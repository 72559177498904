import * as React from 'react';
import useInterval from '@use-it/interval';
import gql from 'graphql-tag';
import {useEffect} from 'react';
import {MessageBar, MessageBarType, Persona, PersonaSize} from 'office-ui-fabric-react';
import FylerWordUtils from '../FylerWordUtils';
import { FylerUtils } from '@verlata/fyler-office-common';

const DocumentHeartbeat = ({ delay = 10000, apiClient, docId, user, asyncCallMade = (_) => {}, unitTest = false }) => {
    const [users, setUsers] = React.useState([]);

    const heartbeatFunction = heartbeat(asyncCallMade, apiClient, docId, user, setUsers);
    useEffect(heartbeatFunction, []);
    if (!unitTest) {
        useInterval(heartbeatFunction, delay);
    }

    if (users.length === 0) {
        return <span/>;
    }
    else {
        const message = <span>This document is also being edited by: {users.map(userToName)}</span>;
        if (unitTest) {
            return message;
        }
        else {
            return <MessageBar messageBarType={MessageBarType.warning} dismissButtonAriaLabel='Close'>
                {message}
            </MessageBar>;
        }
    }
};

function heartbeat(asyncCallMade, apiClient, docId, user, setUsers) {
    return () => {
        asyncCallMade(
            apiClient.mutate({
                mutation: gql(`mutation HasDocumentOpen($doc_id: ID!, $user: UserInput!) {
                    hasDocumentOpen(doc_id: $doc_id, user: $user) {
                        id username email first_name last_name
                    }
                }`),
                variables: {
                    doc_id: docId,
                    user: {
                        username: user['username'],
                        email: user['email'],
                        first_name: user['given_name'],
                        last_name: user['family_name'],
                    }
                }
            }).then((results) => {
                setUsers(results.data['hasDocumentOpen'].filter((nextUser) => {
                    return nextUser['username'] !== user['username'];
                }));
            })
        );
    };
}

const userToName = (user) => {
    return <Persona key={`document_open_${user['username']}`}
                    imageInitials={FylerWordUtils.userInitials(user)}
                    text={FylerUtils.abbreviate(FylerWordUtils.userName(user), 30)}
                    title={user['email']}
                    size={PersonaSize.size24}
                    className={'document-open-user'}/>;
};

export default DocumentHeartbeat;
