import * as React from 'react';
import {DocumentSearch} from '@verlata/fyler-office-common';
import DownloadableFylerDocument from '../DownloadableFylerDocument';
import DownloadableDocumentLink from './DownloadableDocumentLink';

export default class WordDocumentSearch extends React.Component {

    render() {
        let visibleColumns = [
            {
                key: 'file_name',
                name: 'Name',
                fieldName: 'file_name',
                minWidth: 128,
                isResizable: false,
                onRender: (document) => {
                    return <DownloadableDocumentLink apiClient={this.props.apiClient}
                                                     document={new DownloadableFylerDocument(document, this.props.unitTest)}/>;
                }
            }
        ];

        return <DocumentSearch apiClient={this.props.apiClient}
                               columns={visibleColumns}
                               query={this.props.query}
                               unitTest={this.props.unitTest}
                               asyncCallMade={this.props.asyncCallMade} />;
    }

}
