import * as React from 'react';
import { FileDocument } from '@verlata/fyler-office-common';
import gql from 'graphql-tag';
import FilingMatterSearchResult from './FilingMatterSearchResult';
import {MessageBar, MessageBarType} from 'office-ui-fabric-react';

export default class SaveDocumentSearch extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
    };

    state = {
    };

    render() {
        return <FileDocument apiClient={this.props.apiClient}
                             makeSuggestions={this.makeSuggestions}
                             renderResults={this.renderResults}
                             query={this.props.query}
                             asyncCallMade={this.props.asyncCallMade}/>;
    }

    renderResults = (results) => {
        if (results.length === 0) {
            return <MessageBar messageBarType={MessageBarType.info} dismissButtonAriaLabel='Close'>
                No matching folders found
            </MessageBar>;
        }
        else {
            return <FilingMatterSearchResult results={results}
                                             moveToMatterCallback={this.props.saveCallback}
                                             unitTest={this.props.unitTest}/>;
        }
    };

    makeSuggestions = async (callback) => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query Suggest($username: String!) {
                    suggestFolderForDoc(user_name: $username)
                }`),
                variables: {
                    username: this.props.username,
                },
                fetchPolicy: 'no-cache'
            }).then((results) => {
                callback(results.data['suggestFolderForDoc']);
            }).catch((reason) => {
                console.log(`Failed to load suggestions because: ${reason}`);
                callback([]);
            })
        );
    }
}
