import * as React from 'react';
import {
    DetailsList,
    IColumn,
    SelectionMode,
    Text,
} from 'office-ui-fabric-react';
import DownloadableDocumentLink from '../documents/DownloadableDocumentLink';

export default class MatterDocuments extends React.Component {

    static defaultProps = {
        unitTest: false
    };

    state = {
    };

    render() {
        if (this.props.documents.length === 0) {
            return <Text className={'none-message'}>None</Text>;
        }

        return (
            <DetailsList
                items={this.props.documents}
                columns={this.columns()}
                compact={true}
                isHeaderVisible={false}
                selectionMode={SelectionMode.none}
                {...(this.props.unitTest && {
                    skipViewportMeasures: true,
                    onShouldVirtualize: () => { return false; }
                })}
            />
        );
    }

    columns() {
        return [
            {
                key: 'file_name',
                name: 'Name',
                minWidth: 128,
                isResizable: true,
                onRender: (document) => {
                    return (
                        <DownloadableDocumentLink apiClient={this.props.apiClient} document={document}/>
                    );
                }
            }
        ];
    }

}
