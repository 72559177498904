import * as React from 'react';
import Authentication from './auth/Authentication';
import {ProgressIndicator} from 'office-ui-fabric-react';

export class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {};
    }

    render() {
        const { isOfficeInitialized } = this.props;

        if (!isOfficeInitialized) {
            return (
                <ProgressIndicator/>
            );
        }

        return (
            <div>
                <Authentication/>
            </div>
        );
    }
}

export default App;
