export default class CurrentDocumentProperties {

    matterPropertyName = 'telescope.matter.id';
    docIdPropertyName = 'telescope.doc.id';
    versionIdPropertyName = 'telescope.doc.version';

    clear = (callback) => {
        Word.run(async context => {
            let matterIdProperty = context.document.properties.customProperties.getItemOrNullObject(this.matterPropertyName).load();
            let docIdProperty = context.document.properties.customProperties.getItemOrNullObject(this.docIdPropertyName).load();
            let versionIdProperty = context.document.properties.customProperties.getItemOrNullObject(this.versionIdPropertyName).load();
            await context.sync();

            if (!!matterIdProperty) {
                matterIdProperty.delete();
            }
            if (!!docIdProperty) {
                docIdProperty.delete();
            }
            if (!!versionIdProperty) {
                versionIdProperty.delete();
            }
            await context.sync();

            callback();
        });
    };

    get = (callback) => {
        Word.run(async context => {
            let matterIdProperty = context.document.properties.customProperties.getItemOrNullObject(this.matterPropertyName).load();
            let docIdProperty = context.document.properties.customProperties.getItemOrNullObject(this.docIdPropertyName).load();
            let versionIdProperty = context.document.properties.customProperties.getItemOrNullObject(this.versionIdPropertyName).load();
            await context.sync();

            callback({
                docId: docIdProperty.value,
                versionId: versionIdProperty.value,
                matterId: matterIdProperty.value
            });
        });
    };

    set(matterId, docId, versionId, callback = () => {}) {
        Word.run(async context => {
            context.document.properties.customProperties.add(this.matterPropertyName, matterId);
            context.document.properties.customProperties.add(this.docIdPropertyName, docId);
            context.document.properties.customProperties.add(this.versionIdPropertyName, versionId);
            await context.sync();
            context.document.save();

            callback();
        });
    }
}
