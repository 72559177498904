import * as React from 'react';
import {Spinner} from 'office-ui-fabric-react';
import {DocumentLink, DocumentDownloader} from '@verlata/fyler-office-common';

export default class DownloadableDocumentLink extends React.Component {

    static defaultProps = {
        extraButtons: []
    };

    state = {
        downloading: false,
    };

    render() {
        if (this.state.downloading) {
            return <Spinner/>;
        }
        else {
            return <DocumentLink apiClient={this.props.apiClient}
                                 document={this.props.document}
                                 download={this.download}
                                 extraButtons={this.props.extraButtons}/>;
        }
    }

    download = () => {
        this.setState({downloading: true});

        new DocumentDownloader(this.props.apiClient).download(this.props.document.asObject(), (_, downloadUrl) => {
            this.props.document.downloadFrom(downloadUrl);
            this.setState({downloading: false});
        });
    }

}
