import * as React from 'react';
import {Spinner, TextField, PrimaryButton, DefaultButton} from 'office-ui-fabric-react';

export default class RenameDocument extends React.Component {

    state = {
        newName: this.props.document.fileName(),
        saving: false,
    };

    render() {
        if (this.state.saving) {
            return <Spinner/>;
        }
        else {
            return <div>
                <TextField label={'Document Name'}
                           name={'document_name'}
                           value={this.state.newName}
                           onChange={this.nameChanged}
                           required />
                <div style={{marginTop: '0.5rem'}}>
                    <PrimaryButton text={'Save'} onClick={this.rename}/>
                    <DefaultButton text={'Cancel'} onClick={this.props.finished}/>
                </div>
            </div>;
        }
    }

    nameChanged = (event) => {
        this.setState({newName: event.target.value});
    };

    rename = () => {
        this.setState({saving: true}, () => {
            this.props.document.rename(this.props.apiClient, this.props.username, this.state.newName, this.props.finished);
        });
    }

}
