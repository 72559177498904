import {FylerDocument} from '@verlata/fyler-office-common';

export default class DownloadableFylerDocument extends FylerDocument {

    constructor(document, unitTest = false) {
        super(document);
        this.unitTest = unitTest;
    }

    asObject() {
        return super.asObject();
    }

    downloadFrom = (downloadUrl) => {
        window.location.href = downloadUrl;
    };

    emailId() {
        return super.emailId();
    }

    fileName() {
        return super.fileName();
    }

    from() {
        return super.from();
    }

    getMetadata() {
        return super.getMetadata();
    }

    getMetadataValue(name) {
        return super.getMetadataValue(name);
    }

    iconForExtension() {
        return super.iconForExtension();
    }

    id() {
        return this.asObject()['id'];
    }

    isEmail() {
        return super.isEmail();
    }

    matterId() {
        return super.matterId();
    }

    setMetadataValue(name, value) {
        super.setMetadataValue(name, value);
    }

    snippet() {
        return super.snippet();
    }

    timestamp() {
        return super.timestamp();
    }

    timestampAsString() {
        return super.timestampAsString();
    }

    versionId() {
        return this.asObject()['version_id'];
    }

    isUnitTest() {
        return this.unitTest;
    }

}
