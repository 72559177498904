export default class FylerWordUtils {

    static userInitials(user) {
        if (!!user['first_name']) {
            return `${user['first_name'][0]}${user['last_name'][0]}`;
        }
        else {
            return user['email'][0];
        }
    }

    static userName(user) {
        if (!!user['first_name']) {
            return `${user['last_name']}, ${user['first_name']}`;
        }
        else {
            return user['email'];
        }
    }

}
