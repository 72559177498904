import * as React from 'react';
import DocumentTitle from '../documents/DocumentTitle';
import {
    CommandBar,
    Pivot,
    PivotItem,
    PivotLinkSize,
    ProgressIndicator,
    Spinner
} from 'office-ui-fabric-react';
import gql from 'graphql-tag';
import CurrentDocument from '../../CurrentDocument';
import WordDocumentSearch from '../documents/WordDocumentSearch';
import MatterEmails from './MatterEmails';
import MatterDocuments from './MatterDocuments';
import DownloadableFylerDocument from '../DownloadableFylerDocument';
import {MatterFolders, MatterLink} from '@verlata/fyler-office-common';
import SaveDocumentSearch from './SaveDocumentSearch';
import DocumentVersions from '../documents/DocumentVersions';
import RenameDocument from '../documents/RenameDocument';
import DocumentHeartbeat from '../documents/DocumentHeartbeat';
import { UserContext } from '../auth/UserContext';

export default class MatterHome extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        unitTest: false
    };

    state = {
        inited: false,
        currentDocument: null,
        matter: null,
        documentMatter: null,
        renaming: false,
        saving: false,
        savedAt: null,
    };

    componentDidMount() {
        this.init();
    }

    render() {
        if (!this.state.inited) {
            return (<ProgressIndicator/>);
        }
        else if (this.state.renaming) {
            return <RenameDocument apiClient={this.props.apiClient}
                                   username={this.props.username}
                                   document={this.state.currentDocument}
                                   finished={() => {this.setState({renaming: false, inited: false}, this.init)}}/>;
        }
        else if (!!this.state.matter) {
            return this.renderDocument();
        }
        else {
            return this.renderFile();
        }
    }

    renderDocument() {
        return (<div className={'email-panel'}>
            {this.renderTitle()}
            {this.renderHeartbeat()}
            <Pivot linkSize={PivotLinkSize.large} className={'filed-email-matter'}>
                <PivotItem itemIcon={'MapLayers'} headerButtonProps={{title: 'Versions'}}>
                    <DocumentVersions key={`versions-at-${this.state.currentDocument.versionId()}`}
                                      apiClient={this.props.apiClient}
                                      document={this.state.currentDocument}
                                      unitTest={this.props.unitTest}/>
                </PivotItem>
                <PivotItem itemIcon={'TextDocument'} headerButtonProps={{title: 'Documents'}}>
                    <MatterDocuments apiClient={this.props.apiClient} documents={this.state.matter['documents']}
                                     unitTest={this.props.unitTest}/>
                </PivotItem>
                <PivotItem itemIcon={'Mail'} headerButtonProps={{title: 'Emails'}}>
                    <MatterEmails apiClient={this.props.apiClient} emails={this.state.matter['emails']}
                                  unitTest={this.props.unitTest}/>
                </PivotItem>
                {
                    (!!this.state.matter['folders']['parent'] || this.state.matter['folders']['children'].length > 0) &&
                    <PivotItem itemIcon={'FabricFolder'} headerButtonProps={{title: 'Folders'}}>
                        <MatterFolders parent={this.state.matter['folders']['parent']}
                                       children={this.state.matter['folders']['children']}
                                       folderClicked={this.matterClicked}/>
                    </PivotItem>
                }
                <PivotItem itemIcon={'Search'} headerButtonProps={{title: 'Search for documents and emails'}}>
                    <WordDocumentSearch apiClient={this.props.apiClient}/>
                </PivotItem>
            </Pivot>
            {this.renderActions()}
        </div>);
    }

    renderFile() {
        return (<div className={'email-panel'}>
            <Pivot linkSize={PivotLinkSize.large}>
                <PivotItem headerText={'File'}>
                    {
                        this.state.saving ?
                            <ProgressIndicator/> :
                            <SaveDocumentSearch username={this.props.username}
                                                saveCallback={this.moveDocumentToMatter}
                                                apiClient={this.props.apiClient}/>
                    }
                </PivotItem>
                <PivotItem itemIcon={'Search'} headerButtonProps={{title: 'Search for documents and emails'}}>
                    <WordDocumentSearch apiClient={this.props.apiClient}/>
                </PivotItem>
            </Pivot>
            {this.renderActions()}
        </div>);
    }

    renderHeartbeat() {
        return <UserContext.Consumer>
            {user =>
                <DocumentHeartbeat delay={60000}
                                   apiClient={this.props.apiClient}
                                   user={user}
                                   docId={this.state.currentDocument.id()}
                                   asyncCallMade={(_) => this.props.asyncCallMade()}
                                   unitTest={this.props.unitTest} />
            }
        </UserContext.Consumer>;
    }

    copy = () => {
        this.setState({
            inited: false,
            currentDocument: null,
            matter: null,
            documentMatter: null
        }, () => {
            this.props.documentProperties.clear(this.init);
        });
    };

    init = () => {
        this.props.documentProperties.get((properties) => {
            this.loadDocument(properties['matterId'], properties['docId'], properties['versionId']);
        });
    };

    loadDocument = (matterId, docId, versionId) => {
        if (!!docId) {
            this.setState({inited: false}, () => {
                this.props.asyncCallMade(
                    this.props.apiClient.query({
                        query: gql(`query {
                            docVersion(folder_id: "${matterId}", id: "${docId}", version_id: "${versionId}") {
                                id folder_id file_name user_name version_id metadata { name value }
                            }
                        }`),
                    }).then((results) => {
                        this.setState({
                            currentDocument: new CurrentDocument(results.data['docVersion'], this.props.unitTest)
                        }, this.loadMatter);
                    })
                );
            });
        }
        else {
            this.setState({inited: true, saving: false});
        }
    };

    loadMatter = () => {
        if (!!this.state.currentDocument) {
            this.props.asyncCallMade(
                this.props.apiClient.query({
                    query: gql(`query {
                    folder(folder_id: "${this.state.currentDocument.matterId()}") {
                        id description
                        parent { id description }
                        children { id description }
                    }
                    docs(folder_id: "${this.state.currentDocument.matterId()}") { id folder_id file_name user_name snippet metadata { name value } }
                }`),
                    fetchPolicy: 'no-cache'
                }).then((results) => {
                    let matter = null;
                    if (!!results.data['folder']) {
                        let contents = results.data['docs'].map((nextDoc) => {
                            return new DownloadableFylerDocument(nextDoc, this.props.unitTest);
                        }).sort((document0, document1) => {
                            return document1.timestamp() - document0.timestamp();
                        });

                        matter = {
                            id: this.state.currentDocument.matterId(),
                            description: results.data['folder']['description'],
                            folders: {
                                parent: results.data['folder']['parent'],
                                children: results.data['folder']['children'],
                            },
                            documents: contents.filter((nextDocument) => {
                                return !nextDocument.isEmail();
                            }),
                            emails: contents.filter((nextDocument) => {
                                return nextDocument.isEmail();
                            })
                        };
                    }
                    this.setState({
                        inited: true,
                        saving: false,
                        matter: matter,
                        documentMatter: this.state.documentMatter || matter
                    });
                })
            );
        }
        else {
            this.setState({inited: true, saving: false});
        }
    };

    matterClicked = (matter) => {
        this.setState({matterId: matter['id']}, this.loadMatter);
    };

    moveDocumentToMatter = (matter) => {
        this.setState({saving: true});

        CurrentDocument.create(this.props.apiClient, matter['id'], this.props.username, (currentDocument) => {
            this.loadDocument(currentDocument.matterId(), currentDocument.id(), currentDocument.versionId());
        });
    };

    rename = () => {
        this.setState({renaming: true});
    };

    renderActions() {
        const items = [];
        if (!!this.state.matter) {
            items.push(
                {
                    key: 'save',
                    name: 'Save',
                    iconProps: {
                        iconName: 'Save'
                    },
                    onClick: this.save,
                    ...(this.state.saving && {
                        commandBarButtonAs: () => { return <Spinner/>; }
                    })
                },
                {
                    key: 'rename',
                    name: 'Rename',
                    iconProps: {
                        iconName: 'Rename'
                    },
                    onClick: this.rename
                },
                {
                    key: 'copy',
                    name: 'Copy',
                    iconProps: {
                        iconName: 'Copy'
                    },
                    onClick: this.copy
                });
        }

        return <CommandBar
            className={'actions'}
            items={items}
            farItems={[
                {
                    key: 'signOut',
                    name: 'Sign Out',
                    iconProps: {
                        iconName: 'SignOut'
                    },
                    iconOnly: true,
                    onClick: () => this.props.signOut()
                },
            ]}
        />;
    }

    renderTitle() {
        return <div>
            <DocumentTitle document={this.state.currentDocument}
                           matter={this.state.documentMatter}
                           savedAt={this.state.savedAt}/>

            {
                !this.showingDocumentMatter() &&
                <div style={{padding: '0.25rem'}}>
                    <MatterLink id={this.state.currentDocument.matterId()} description={this.state.matter['description']} size={'large'}/>
                </div>
            }
        </div>;
    }

    save = () => {
        this.setState({saving: true});

        this.state.currentDocument.save(this.props.apiClient, this.props.username, (currentDocument) => {
            this.setState({
                saving: false,
                savedAt: new Date(),
                currentDocument: currentDocument
            });
        });
    };

    showingDocumentMatter = () => {
        return !!this.state.documentMatter &&
            (this.state.documentMatter['id'] === this.state.matter['id']);
    };
}
