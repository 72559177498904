import * as React from 'react';
import Amplify, { Auth } from 'aws-amplify';
import {PureComponent} from 'react';
import Home from '../Home';
import { SignInForm, TenantLogin, FylerLogger } from '@verlata/fyler-office-common';
import {ProgressIndicator} from 'office-ui-fabric-react';
import { UserContext } from './UserContext';
import { ApiClientContext } from '../ApiClientContext';
import AppSyncClientFactory from '../AppSyncClientFactory';

export default class Authentication extends PureComponent {

    state = {
        apiClient: null,
        initing: true,
        user: null,
        errorMessage: null,
        signingIn: false,
    };
    tenantLogin = new TenantLogin(this.configureAuth);

    componentWillMount() {
        this.loadUser();
    }

    render() {
        if (this.state.initing) {
            return <ProgressIndicator/>;
        }
        else if (!!this.state.user) {
            return <UserContext.Provider value={this.state.user}>
                <ApiClientContext.Provider value={this.state.apiClient}>
                    <Home username={this.state.user.email} signOut={this.signOut}/>
                </ApiClientContext.Provider>
            </UserContext.Provider>;
        }
        else {
            return <SignInForm login={this.login} errorMessage={this.state.errorMessage} loading={this.state.signingIn}/>;
        }
    }

    configureAuth(configuration) {
        Amplify.configure(configuration);
    }

    login = (username, password) => {
        this.setState({signingIn: true}, () => {
            try {
                this.tenantLogin.setUsername(username);
                Auth.signIn({ username, password })
                    .then(() => {
                        this.tenantLogin.loginSuccessful();
                        this.loadUser();
                        this.setState({signingIn: false});
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({signingIn: false, errorMessage: err.message});
                    });
            }
            catch (e) {
                this.setState({signingIn: false, errorMessage: e.message});
            }
        });
    };

    loadUser = () => {
        if (this.tenantLogin.isLoggedIn()) {
            Auth.currentAuthenticatedUser()
                .then(data => {
                    const user = {username: data.username, ...data.attributes};
                    this.setState({
                        initing: false,
                        user: user,
                        apiClient: AppSyncClientFactory.newClient(this.tenantLogin.getRegion())
                    });
                })
                .catch(err => {
                    console.log(err);
                    this.setState({initing: false, errorMessage: err.message});
                });
        }
        else {
            this.setState({initing: false});
        }
    };

    signOut = () => {
        Auth.signOut()
            .then((_) => this.setState({user: null, signingIn: false}))
            .catch(err => FylerLogger.error(`Failed to sign out: ${JSON.stringify(err)}`));
    };
}
