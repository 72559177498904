import * as React from 'react';
import gql from 'graphql-tag';
import {
    DetailsList,
    DetailsRow,
    Link,
    ProgressIndicator,
    SelectionMode
} from 'office-ui-fabric-react';
import * as moment from 'moment';
import { FylerUtils } from '@verlata/fyler-office-common';
import DownloadableFylerDocument from '../DownloadableFylerDocument';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';

const theme = getTheme();

export default class DocumentVersions extends React.Component {

    static defaultProps = {
        asyncCallMade: () => {},
        unitTest: false
    };

    state = {
        versions: [],
        loading: true
    };

    componentDidMount() {
        this.loadVersions();
    }

    render() {
        if (this.state.loading) {
            return <ProgressIndicator/>;
        }
        else {
            return (
                <DetailsList
                    items={this.state.versions}
                    columns={this.columns()}
                    compact={true}
                    isHeaderVisible={false}
                    selectionMode={SelectionMode.none}
                    onRenderRow={this.onRenderRow}
                    {...(this.props.unitTest && {
                        skipViewportMeasures: true,
                        onShouldVirtualize: () => { return false; }
                    })}
                />
            );
        }
    }

    columns() {
        return [
            {
                key: 'number',
                name: '',
                minWidth: 48,
                maxWidth: 64,
                onRender: (version, index) => {
                    return version.thisVersion ?
                        <span className={'current-version'}>
                            {`Version ${this.state.versions.length - index}`}
                        </span> :
                        <Link href={'#'} onClick={() => this.download(version)}>
                            {`Version ${this.state.versions.length - index}`}
                        </Link>;
                }
            },
            {
                key: 'timestamp',
                name: 'When',
                minWidth: 48,
                maxWidth: 64,
                onRender: (version) => {
                    const timestamp = moment.unix(version['timestamp']);
                    return <span title={timestamp.format('HH:mm:ss DD MMM YYYY')}>
                        {timestamp.fromNow()}
                    </span>;
                }
            },
            {
                key: 'who',
                name: 'Who',
                minWidth: 128,
                onRender: (version) => {
                    return <span>
                        {version['who']}
                    </span>;
                }
            },
        ];
    }

    download = (version) => {
        this.props.apiClient.query({
            query: gql(`query {docVersion(folder_id: "${this.props.document.matterId()}", id: "${this.props.document.id()}", version_id: "${version['id']}") { download_url }}`),
            fetchPolicy: 'no-cache'
        }).then((results) => {
            new DownloadableFylerDocument({id: this.props.document.id(), folder_id: this.props.document.matterId()}, this.props.unitTest)
                .downloadFrom(results.data['docVersion']['download_url']);
        });
    };

    loadVersions = () => {
        this.props.asyncCallMade(
            this.props.apiClient.query({
                query: gql(`query {
                    docVersions(folder_id: "${this.props.document.matterId()}", id: "${this.props.document.id()}") {
                        id timestamp etag metadata { name value }
                    }
                }`),
                fetchPolicy: 'no-cache'
            }).then((results) => {
                this.setState({
                    loading: false,
                    versions: results.data['docVersions'].map((nextVersion) => {
                        return {
                            id: nextVersion['id'],
                            timestamp: nextVersion['timestamp'],
                            who: FylerUtils.getMetadataValue(nextVersion['metadata'], 'user'),
                            thisVersion: this.props.document.versionId() === nextVersion['id'],
                            differentToThis: false
                        };
                    }).sort((version0, version1) => {
                        return version1['timestamp'] - version0['timestamp'];
                    })
                });
            })
        );
    };

    onRenderRow = (props) => {
        const customStyles = {};
        if (props.item.thisVersion && props.item.differentToThis) {
            customStyles.root = { backgroundColor: theme.palette.yellowLight };
            props['title'] = 'This document has local modifications that have not been persisted to the server';
        }

        return <DetailsRow {...props} styles={customStyles} />;
    }

}
