import * as React from 'react';
import {
    Text, MessageBar, MessageBarType, Icon
} from 'office-ui-fabric-react';
import { MatterLink } from '@verlata/fyler-office-common';
import * as moment from 'moment';

export default class DocumentTitle extends React.Component {

    state = {
    };

    render() {
        return (
            <MessageBar
                messageBarType={MessageBarType.success}
                isMultiline={true}>
                <div style={{marginBottom: '0.5rem'}}>
                    <Text variant={'large'}>
                        {this.props.document.fileName()}
                    </Text>
                </div>
                <div className={'center-vertically'}>
                    <Icon iconName={'FabricFolder'} className={'icon-for-text'}/>
                    <MatterLink id={this.props.matter['id']} description={this.props.matter['description']} size={'medium'}/>
                </div>
                {
                    !!this.props.savedAt &&
                    <div className={'center-vertically'}>
                        <Icon iconName={'Save'} className={'icon-for-text'}/>
                        <Text variant={'small'} style={{fontStyle: 'italic'}}>
                            {`Saved ${moment(this.props.savedAt).format('HH:mm MMM DD')}`}
                        </Text>
                    </div>
                }
            </MessageBar>
        );
    }
}
