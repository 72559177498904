import * as React from 'react';
import MatterHome from './matter/MatterHome';
import CurrentDocumentProperties from '../CurrentDocumentProperties';
import { ApiClientContext } from './ApiClientContext';

export default class Home extends React.Component {

    state = {
    };

    render() {
        return <ApiClientContext.Consumer>
            {apiClient =>
                <MatterHome apiClient={apiClient}
                            username={this.props.username}
                            documentProperties={new CurrentDocumentProperties()}
                            signOut={this.props.signOut}/>
            }
        </ApiClientContext.Consumer>;
    }
}
