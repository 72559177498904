import gql from 'graphql-tag';
import axios from 'axios';
import CurrentDocumentProperties from './CurrentDocumentProperties';
import DownloadableFylerDocument from './components/DownloadableFylerDocument';
const uuidv1 = require('uuid/v1');
const md5 = require('js-md5');

export default class CurrentDocument extends DownloadableFylerDocument {

    constructor(document, unitTest = false) {
        super(document, unitTest)
    }

    static create(apiClient, matterId, username, successCallback) {
        Word.run((context) => {
            //context.document.save(); To be uncommented pending bug https://github.com/OfficeDev/office-js/issues/1059

            return context.sync().then(() => {
                Office.context.document.getFilePropertiesAsync((fileProperties) => {
                    const documentName = CurrentDocument.documentNameFromProperties(fileProperties.value);
                    const versionId = uuidv1();
                    apiClient.mutate({
                        mutation: gql(`mutation CreateDoc($matterId: String!, $username: String!, $fileName: String!, $versionId: ID!) {
                            createDoc(folder_id: $matterId, user_name: $username, file_name: $fileName, version_id: $versionId) {
                                id
                                upload {
                                    url
                                    headers { name value }
                                }
                            }
                        }`),
                        variables: {
                            matterId: matterId,
                            username: username,
                            fileName: documentName,
                            versionId: versionId
                        }
                    }).then((results) => {
                        const id = results.data['createDoc']['id'];
                        const uploadUrl = results.data['createDoc']['upload']['url'];
                        const uploadHeaders = CurrentDocument.arrayToHash(results.data['createDoc']['upload']['headers']);

                        CurrentDocument.upload(matterId, id, versionId, uploadUrl, uploadHeaders, () => {
                            successCallback(new CurrentDocument({
                                id: id,
                                version_id: versionId,
                                folder_id: matterId
                            }));
                        });
                    });
                });
            });
        });
    }

    hash(successCallback) {
        Office.context.document.getFileAsync(Office.FileType.Compressed, {sliceSize: 65536}, (result) => {
            if (result.status === Office.AsyncResultStatus.Succeeded) {
                CurrentDocument.readDocument(result.value, (documentBytes) => {
                    successCallback(md5(documentBytes));
                });
            }
            else {
                console.log(`Failed to get document content for hash: ${JSON.stringify(result)}`);
            }
        });
    }

    rename = (apiClient, username, fileName, successCallback) => {
        this.asObject()['file_name'] = fileName;

        this.save(apiClient, username, successCallback);
    };

    save = (apiClient, username, successCallback) => {
        Word.run((context) => {
            //context.document.save(); To be uncommented pending bug https://github.com/OfficeDev/office-js/issues/1059

            return context.sync().then(() => {
                this.documentName((documentName) => {
                    const newVersionId = uuidv1();

                    apiClient.mutate({
                        mutation: gql(`mutation UpdateDoc($matterId: String!, $docId: ID!, $username: String!, $fileName: String!, $versionId: ID!) {
                        updateDoc(folder_id: $matterId, doc_id: $docId, user_name: $username, file_name: $fileName, version_id: $versionId) {
                            id
                            upload {
                                url
                                headers { name value }
                            }
                        }
                    }`),
                        variables: {
                            matterId: this.matterId(),
                            docId: this.id(),
                            username: username,
                            fileName: documentName,
                            versionId: newVersionId
                        }
                    }).then((results) => {
                        this.asObject()['id'] = results.data['updateDoc']['id'];
                        let uploadUrl = results.data['updateDoc']['upload']['url'];
                        let uploadHeaders = CurrentDocument.arrayToHash(results.data['updateDoc']['upload']['headers']);

                        CurrentDocument.upload(this.matterId(), this.id(), newVersionId, uploadUrl, uploadHeaders, () => {
                            successCallback(this.bumpVersion(newVersionId));
                        });
                    });
                });
            });
        });
    };

    static arrayToHash(array) {
        return array.reduce((headers, nextItem) => {
            headers[nextItem.name] = nextItem.value;
            return headers;
        }, {});
    }

    bumpVersion(newVersionId) {
        const newDocument = {...this.asObject()};
        newDocument['version_id'] = newVersionId;
        return new CurrentDocument(newDocument, this.isUnitTest());
    }

    documentName(successCallback) {
        let name = this.fileName();
        if (!!name) {
            successCallback(name);
        }
        else {
            Office.context.document.getFilePropertiesAsync((fileProperties) => {
                successCallback(CurrentDocument.documentNameFromProperties(fileProperties.value));
            });
        }
    }

    static documentNameFromProperties(properties) {
        const url = properties.url;
        if (url.includes('\\')) {
            //Windows
            return url.split('\\').pop();
        }
        else {
            return url.split('/').pop();
        }
    }

    static readDocument(document, completionCallback, contentSoFar = new Uint8Array(0), sliceIndex = 0) {
        document.getSliceAsync(sliceIndex, (result) => {
            try {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    let data = result.value.data;
                    if (data) {
                        let newContent = new Uint8Array(contentSoFar.length + data.length);
                        newContent.set(contentSoFar, 0);
                        newContent.set(data, contentSoFar.length);

                        if ((sliceIndex + 1) < document.sliceCount) {
                            CurrentDocument.readDocument(document, completionCallback, newContent, sliceIndex + 1);
                        }
                        else {
                            document.closeAsync();
                            completionCallback(newContent);
                        }
                    }
                }
                else {
                    document.closeAsync();
                    console.log(`Failed to get document slice: ${JSON.stringify(result)}`);
                }
            }
            catch (e) {
                document.closeAsync();
                throw e;
            }
        });
    };

    static upload(matterId, id, versionId, url, headers, successCallback) {
        new CurrentDocumentProperties().set(matterId, id, versionId, () => {
            Office.context.document.getFileAsync(Office.FileType.Compressed, {sliceSize: 65536},
                (result) => {
                    if (result.status === Office.AsyncResultStatus.Succeeded) {
                        let document = result.value;

                        console.log(`Uploading document of ${document.size} bytes for doc ${id} and version ${versionId} to matter ${matterId}`);
                        CurrentDocument.readDocument(document, (documentBytes) => {
                            headers['Content-Type'] = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                            axios.put(url, documentBytes, {headers: headers})
                                .then((uploadResponse) => {
                                    const versionId = uploadResponse.headers['x-amz-version-id'];
                                    successCallback(versionId);
                                });
                        });
                    } else {
                        console.log(`Failed to get document content: ${JSON.stringify(result)}`);
                    }
                });
        });
    }
}
